
import { defineComponent, onMounted, ref } from "vue";
import cardArticle from "@/components/card-article/card-article.vue"
import { useRoute } from "vue-router";
import { getArticle } from "@/api"
import router from "@/router";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    cardArticle
  },
  setup() {
    const route = useRoute()

    const keyword = ref<string>("");
    const ranking = ref("");
    const searchTitle = ref("");
    const articles = ref("");
    const loading = ref(false);
    // 获取文章参数
    const articleParams = {
      title: "",
      pagenum: 1,
      pagesize: 8
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 1,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
        window.scrollTo(0, 0);
      })
    }
    // 搜索
    const onSearch = (value: string) => {
      if (!value) {
        return message.warning("请输入文章标题!");
      }
      if (value.length > 15) {
        return message.warning("你输入的文章标题太长!");
      }
      router.push(`/search?keyword=${value}`);
      searchTitle.value = value;
      articleParams.title = value;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      })
    }
    // 路由跳转
    const goArticle = (id: string) => {
      router.push(`/article?id=${id}`)
    }

    onMounted(() => {
      keyword.value = route.query.keyword as string ;
      searchTitle.value = keyword.value;
      document.title = `'${keyword.value}'的搜索结果-木小天`;
      articleParams.title = keyword.value;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      })
      getArticle({title: "", pagenum: 1, pagesize: 5, order:"zan_num"}).then((res: any) => {
        ranking.value = res.data.data.articles
      })
    })

    return {
      articles,
      keyword,
      loading,
      articleParams,
      ranking,
      searchTitle,
      pagination,
      changePage,
      onSearch,
      goArticle
    }
  }

})
