<template>
  <a-spin :spinning="loading" tip="加载中" :delay="500">
    <div id="search" class="center">
      <div class="left">
        <div class="text-wrapper">
          <p>搜索结果</p>
          <h1># {{ searchTitle }}</h1>
          <p>为您查询到<span>{{ pagination.total }}</span>条</p>
        </div>
      </div>
      <div class="middle">
        <div class="article">
          <div class="search">
            <a-input-search
              v-model:value="keyword"
              placeholder="请输入文章标题进行搜索"
              style="width: 90%;margin:0 5%;"
              enter-button
              @search="onSearch"
            />
          </div>
          <div class="title-wrapper">
            <div class="title">全部</div>
          </div>
          <div v-if="pagination.total">
            <ul>
              <li v-for="item in articles" :key="item.id">
                <card-article :article="item"></card-article>
              </li>
            </ul>
            <div style="text-align: center;">
              <a-pagination 
                v-model:current="pagination.current" 
                :total="pagination.total" 
                v-model:pageSize="pagination.pagesize"
                show-less-items 
                @change="changePage"
              />
            </div>
          </div>
          <div v-else class="error">没有搜到改内容！</div>
        </div>
      </div>
      <div class="right">
        <div class="love">
          <div class="title-wrapper">
            <div class="title">精品推荐</div>
          </div>
          <ul>
            <li @click="goArticle(item.id)" v-for="(item, index) in ranking" :key="index">
              <img v-if="index == 0" :src="item.cover" alt="">
              <div class="title">{{ item.title }}</div>
              <div class="time">{{ item.time }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import cardArticle from "@/components/card-article/card-article.vue"
import { useRoute } from "vue-router";
import { getArticle } from "@/api"
import router from "@/router";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    cardArticle
  },
  setup() {
    const route = useRoute()

    const keyword = ref<string>("");
    const ranking = ref("");
    const searchTitle = ref("");
    const articles = ref("");
    const loading = ref(false);
    // 获取文章参数
    const articleParams = {
      title: "",
      pagenum: 1,
      pagesize: 8
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 1,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
        window.scrollTo(0, 0);
      })
    }
    // 搜索
    const onSearch = (value: string) => {
      if (!value) {
        return message.warning("请输入文章标题!");
      }
      if (value.length > 15) {
        return message.warning("你输入的文章标题太长!");
      }
      router.push(`/search?keyword=${value}`);
      searchTitle.value = value;
      articleParams.title = value;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      })
    }
    // 路由跳转
    const goArticle = (id: string) => {
      router.push(`/article?id=${id}`)
    }

    onMounted(() => {
      keyword.value = route.query.keyword as string ;
      searchTitle.value = keyword.value;
      document.title = `'${keyword.value}'的搜索结果-木小天`;
      articleParams.title = keyword.value;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      })
      getArticle({title: "", pagenum: 1, pagesize: 5, order:"zan_num"}).then((res: any) => {
        ranking.value = res.data.data.articles
      })
    })

    return {
      articles,
      keyword,
      loading,
      articleParams,
      ranking,
      searchTitle,
      pagination,
      changePage,
      onSearch,
      goArticle
    }
  }

})
</script>

<style lang="scss" scoped>
  #search {
    display: flex;
    justify-content: space-between;

    .left {
      width: 13%;

      .text-wrapper {

        p {
          font-size: 12px;
          color: #999;

          span {
            color: #2254f4;
            margin: 0 5px;
            font-weight: 600;
          }
        }

        h1 {
          font-size: 32px;
          color: #333;
          line-height: 36px;
          margin-bottom: 10px;
          font-weight: 600;
        }
      }
    }

    .middle {
      width: 60%;

      .search {
        width: 100%;
        margin-bottom: 20px;

        ::v-deep(.ant-input-search) {
          width: 100% !important;
          margin: 0 !important;
        }
      }

      .article {
        border-radius: 6px;
      }

      .title-wrapper {
        padding: 20px;
        background: white;
      }

      ul {
        margin-top: 20px;

        li {
          padding: 20px;
          background: white;
          border-radius: 6px;
          margin-bottom: 20px;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid rgba(34, 84, 244,.5);
          }
        }
      }

      .error {
        background: white;
        padding: 20px;
        font-weight: 600;
      }
    }

    .right {
      width: 23%;

      .love {
        background: white;
        padding: 20px;
        border-radius: 6px;
        ul {

          li {
            padding: 15px 0;
            border-bottom: 1px solid #eee;
            cursor: pointer;

            &:hover {
              
              .title {
                color: #2254f4;
              }
            }

            img {
              display: block;
              width: 240px;
            }

            .title {
              color: #454545;
              margin: 10px 0px;
              font-size: 14px;
              font-weight: 600;
            }

            .time {
              font-size: 12px;
              color: #999;
            }
          }
        }
      }
    }
  }

    // 移动端
  @media screen and (max-width: 800px) {
    #search {

      .left {
        display: none;
      }

      .middle {
        width: 100%;
        min-height: 500px;
      }

      .right {
        display: none;
      }
    }
  }
</style>